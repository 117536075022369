import {
  CenterFocusStrongOutlined,
  ColorLensOutlined,
  Edit,
  FitScreen,
  FolderOpenOutlined,
  PermMediaOutlined,
  RemoveCircleOutlineOutlined,
} from "@mui/icons-material";
import { ButtonBase, IconButton, styled, Tooltip, Typography } from "@mui/material";
import type { FieldProps } from "@rjsf/utils";
import type { DragEvent, FC } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PreviewContainer } from "./PreviewContainer";
import { ActionButtonRow } from "./ActionButtonRow";
import { ActionButtonContainer } from "./ActionButtonContainer";
import { SelectMediaLabel } from "./SelectMediaLabel";
import { iseAspectRatioValid } from "./utils";
import Styles from "@/assets/styles/Styles";
import MediaPreview from "@/components/MediaPreview/MediaPreview";
import type { FilterProps } from "@/components/MediaSelector/ColorFiltersModal";
import ColorFiltersModal, { DEFAULT_FILTERS } from "@/components/MediaSelector/ColorFiltersModal";
import type { FocalPointProps } from "@/components/MediaSelector/FocalPointModal";
import FocalPointModal, {
  DEFAULT_FOCAL_POINT,
  FOCAL_POINT_ALLOWED_MEDIA_TYPES,
} from "@/components/MediaSelector/FocalPointModal";
import { useMediaSelector } from "@/components/MediaSelector/useMediaSelector";
import type Media from "@/declarations/models/Media";
import MediaType from "@/declarations/models/MediaType";
import KioTitle from "@/framework/KioForm/common/KioTitle";
import Loader from "@/framework/Loader";
import { useDebounce } from "@/hooks/useDebounce";
import { useLoadingState } from "@/hooks/useLoadingState";
import Api from "@/services/Api";
import { validNumber } from "@/utils/numbers";
import { getSchemaTitle } from "@/utils/schema";
import { ScalingPopover } from "@/components/MediaSelector/ScalingPopover";
import IsFromSharedInstanceIndicator from "@/components/IsFromSharedInstanceIndicator";
import { useModal } from "@/framework/ModalManager/useModal";

export interface MediaSelectorFieldImageDimensions {
  height: number;
  width: number;
}

export type MediaSelectorFieldsetImageDimensions = (
  value:
    | ((prevState: MediaSelectorFieldImageDimensions | null) => MediaSelectorFieldImageDimensions | null)
    | MediaSelectorFieldImageDimensions
    | null
) => void;

export interface MediaSelectorFieldOptions {
  mediaTypes?: Array<MediaType>;
  showFocalPoint?: boolean;
  showColorFilters?: boolean;
  showScaling?: boolean;
  aspectRatio?: string | undefined;
  selectMediaLabel?: { "ui:label": string };
}

const defaultOptions: MediaSelectorFieldOptions = {
  mediaTypes: [],
  showFocalPoint: false,
  showColorFilters: false,
  showScaling: false,
  aspectRatio: "",
};

const Container = styled("div")({
  display: "flex",
  flexFlow: "column",
  justifyContent: "flex-start",
  alignItems: "stretch",
  width: "100%",
});

const SharedMediaIndicatorElement = styled("span")`
  z-index: 2;

  & div {
    background-color: #f2f2f24d;
  }
`;

const MarkerContainer = styled("div")`
  position: absolute;
  z-index: 1;
`;

const Marker = styled("div")(({ theme }) => ({
  border: `4px solid ${theme.palette.getContrastText(Styles.Colors.THEME_PRIMARY)}`,
  lineHeight: "100%",
  position: "absolute",
  boxSizing: "content-box",
  textAlign: "center",
  backgroundColor: Styles.Colors.THEME_PRIMARY,
  color: theme.palette.getContrastText(Styles.Colors.THEME_PRIMARY),
  transform: "translate(-50%, -50%)",
  cursor: "grab",
}));

const FocalPointMarker = styled(Marker)`
  height: 48px;
  width: 48px;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 80%;
  cursor: default;
`;

const ScaleIndicator = styled("div")`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  font-size: 12px;
`;

const MEDIA_TYPES = Object.values(MediaType);
const DEFAULT_FORM_DATA = {};

interface MediaSelectorFieldProps extends FieldProps {
  markers?: { x: number; y: number; index: number }[];
  onMarkerDrop?: (i: number, e: DragEvent<HTMLElement>) => void;
}

const getMarkerAspectStyling = (contAspectRatio: string | undefined, imageDims: MediaSelectorFieldImageDimensions) => {
  if (contAspectRatio) {
    const contArray = contAspectRatio.split(":");
    if (contArray.length === 2) {
      const contDims: MediaSelectorFieldImageDimensions = {
        width: Number(contArray[0]),
        height: Number(contArray[1]),
      };
      if (contDims.width / contDims.height < imageDims.width / imageDims.height) {
        return {
          width: "100%",
          aspectRatio: `${imageDims.width}/${imageDims.height}`,
        };
      }
      return {
        height: "100%",
        aspectRatio: `${imageDims.width}/${imageDims.height}`,
      };
    }
  }
  return {
    height: "100%",
    maxWidth: "100%",
    aspectRatio: `${imageDims.width}/${imageDims.height}`,
  };
};

/**
 * A field that allows the selection of media.
 *
 * @see client/src/framework/KioForm/definitions/global/Media.ts
 * @see client/src/framework/KioForm/fields/ImageMapField.tsx
 */
export const MediaSelectorField: FC<MediaSelectorFieldProps> = ({
  schema,
  uiSchema,
  formContext,
  formData = DEFAULT_FORM_DATA,
  onChange,
  required,
  disabled,
  readonly,
  id,
  markers,
  onMarkerDrop,
}) => {
  const { t } = useTranslation("common");
  const uiOptions = uiSchema?.["ui:options"];
  const {
    mediaTypes,
    showFocalPoint,
    showColorFilters,
    aspectRatio,
    selectMediaLabel,
    showScaling,
  }: MediaSelectorFieldOptions = {
    ...defaultOptions,
    ...uiOptions,
  };
  const selectLabel = selectMediaLabel?.["ui:label"] || t("kioForm.widgets.MediaSelectorField.selectMedia");

  const [imageDimensions, setImageDimensions] = useState<MediaSelectorFieldImageDimensions | null>(null);
  const handleUnselectMediaTimeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const handleMediaSelectedTimeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const [selectedMedia, setSelectedMedia] = useState<Media | null>(null);
  const { isOpen, openMediaDrawer, closeMediaDrawer } = useMediaSelector();
  const { isLoading, startLoading, stopLoading } = useLoadingState();
  const [focalPointModalOpen, setFocalPointModalOpen] = useState(false);
  const [currentFocalPoint, setCurrentFocalPoint] = useState(
    validNumber(formData.focalPoint?.x) ? formData.focalPoint : DEFAULT_FOCAL_POINT
  );
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [scalingPopoverOpen, setScalingPopoverOpen] = useState(false);
  const showScalingButtonRef = useRef<HTMLButtonElement | null>(null);

  const title = getSchemaTitle(formContext, schema, uiSchema, formData);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const isMediaFromSharedInstance = selectedMedia?.application_instance_id !== formContext?.applicationInstanceId;
  const showFocalPointButton =
    !!showFocalPoint && FOCAL_POINT_ALLOWED_MEDIA_TYPES.includes(selectedMedia?.media_type as string);

  const aspectRatioStyle =
    !!aspectRatio && iseAspectRatioValid(aspectRatio)
      ? { aspectRatio: aspectRatio.replace(":", "/"), minHeight: "auto" }
      : {};

  const handleUnselectMedia = () => {
    handleUnselectMediaTimeoutIdRef.current = setTimeout(() => {
      onChange({
        ...formData,
        mediaId: null,
        focalPoint: DEFAULT_FOCAL_POINT,
        scale: null,
      });
      handleUnselectMediaTimeoutIdRef.current = null;
    });
  };

  const handleMediaSelected = (media: Media) => {
    handleMediaSelectedTimeoutIdRef.current = setTimeout(() => {
      onChange({
        ...formData,
        mediaId: media.id,
      });
      handleMediaSelectedTimeoutIdRef.current = null;
    });
    closeMediaDrawer();
  };

  const onFocalPointChange = (focalPointObj: FocalPointProps): void => {
    setCurrentFocalPoint(focalPointObj);
    onChange({ ...formData, focalPoint: { ...focalPointObj } });
  };

  const onScaleChange = (scale?: number): void => {
    onChange({ ...formData, scale });
  };

  const onFiltersChange = (filtersObj: FilterProps): void => {
    onChange({ ...formData, filters: { ...filtersObj } });
  };

  const toggleMediaDrawer = () => {
    if (isOpen) {
      closeMediaDrawer();
    } else {
      openMediaDrawer(mediaTypes || [], handleMediaSelected);
    }
  };

  const modal = useModal(
    "editMediaView",
    {
      mediaIdProp: selectedMedia?.id,
      modalEditor: true,
      disablePreview: true,
      isDirty,
      setIsDirty,
    },
    useCallback(
      (close: () => void) => ({
        onClose: () => {
          // Moved isDirtyPrompt from here to EditDocumentLoader because of state being stale.
          close();
        },
      }),
      [isDirty, t]
    )
  );

  useEffect(
    () => () => {
      if (handleUnselectMediaTimeoutIdRef.current !== null) clearTimeout(handleUnselectMediaTimeoutIdRef.current);
      if (handleMediaSelectedTimeoutIdRef.current !== null) clearTimeout(handleMediaSelectedTimeoutIdRef.current);
    },
    []
  );

  const debouncedFetchMedia = useDebounce<string>(200, () => {
    const { mediaId } = formData;
    if (!mediaId && selectedMedia) setSelectedMedia(null);
    else if (mediaId && selectedMedia?.id !== mediaId) {
      startLoading();
      const loadMedia = Api.getOneMedia(mediaId);
      loadMedia
        .fetchDirect(null)
        .then((media) => setSelectedMedia(media))
        .then(loadMedia.abort)
        .finally(stopLoading);
    }
  });

  useEffect(() => {
    debouncedFetchMedia();
  }, [debouncedFetchMedia, formData.mediaId]);

  useEffect(() => {
    if (!formData.mediaId) setCurrentFocalPoint(DEFAULT_FOCAL_POINT);
    setCurrentFocalPoint(validNumber(formData.focalPoint?.x) ? formData.focalPoint : DEFAULT_FOCAL_POINT);
  }, [formData.focalPoint, formData.mediaId]);

  if (schema?.type !== "object") {
    return (
      <Typography title={JSON.stringify(schema)} color="error">
        {t("kioForm.widgets.MediaSelectorField.invalidFieldType", { fieldType: "object" })}
      </Typography>
    );
  }

  // TODO se over denne
  // Usikker på akkurat hva denne sjekken gjør (typeof mediaId !== boolean ?!),
  // endret til å støtte ny mediaId-type ('integer' -> ['integer', 'null'])
  const mediaIdProp = schema.properties?.["mediaId"];
  if (
    typeof mediaIdProp !== "boolean" &&
    !(Array.isArray(mediaIdProp?.type) && mediaIdProp?.type.includes("integer"))
  ) {
    return <Typography color="error">{t("kioForm.widgets.MediaSelectorField.invalidShape")}</Typography>;
  }

  if (
    !Array.isArray(mediaTypes) ||
    !mediaTypes.length ||
    mediaTypes.some((mediaType) => !MEDIA_TYPES.includes(mediaType))
  ) {
    return (
      <Typography color="error">
        {t("kioForm.widgets.MediaSelectorField.invalidMediaType", {
          mediaTypes: MEDIA_TYPES.join(", "),
        })}
      </Typography>
    );
  }

  const handleDrop = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    const index = e.dataTransfer.getData("index");
    if (index && validNumber(index)) {
      onMarkerDrop?.(Number(index), e);
    }
  };

  const allowDrop = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
  };

  const handleDragStart = (i: number, e: DragEvent<HTMLElement>) => {
    e.dataTransfer.setData("index", `${i}`);
  };

  const handleEdit = () => {
    if (selectedMedia?.id) {
      modal.open();
    }
  };

  const getContentElement = () => {
    if (isLoading) {
      return (
        <PreviewContainer style={aspectRatioStyle}>
          <Loader loadingText={t("kioForm.widgets.MediaSelectorField.loadingMedia")} darkBackground />
        </PreviewContainer>
      );
    }
    if (selectedMedia) {
      return (
        <PreviewContainer
          style={aspectRatioStyle}
          className={[MediaType.AUDIO, MediaType.DOCUMENT].includes(selectedMedia.media_type) ? "small" : ""}
        >
          <ActionButtonRow>
            {isMediaFromSharedInstance && (
              <SharedMediaIndicatorElement>
                <IsFromSharedInstanceIndicator
                  applicationInstanceId={selectedMedia.application_instance_id}
                  selectedLocale={formContext.selectedLocale}
                />
              </SharedMediaIndicatorElement>
            )}
            {!isMediaFromSharedInstance && (
              <ActionButtonContainer>
                <Tooltip title={t("kioForm.widgets.MediaSelectorField.editMetaData") || "Edit"}>
                  <IconButton color="primary" size="small" onClick={handleEdit}>
                    <Edit />
                  </IconButton>
                </Tooltip>
              </ActionButtonContainer>
            )}
            <ActionButtonContainer>
              <Tooltip title={selectLabel || "Select"}>
                <IconButton color="primary" size="small" onClick={toggleMediaDrawer}>
                  <FolderOpenOutlined />
                </IconButton>
              </Tooltip>
            </ActionButtonContainer>
            {showFocalPointButton && (
              <ActionButtonContainer>
                <Tooltip title={t("kioForm.widgets.MediaSelectorField.focalPoint") || "Set focal point"}>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setFocalPointModalOpen(true);
                    }}
                  >
                    <CenterFocusStrongOutlined />
                  </IconButton>
                </Tooltip>
              </ActionButtonContainer>
            )}
            {showColorFilters && (
              <ActionButtonContainer>
                <Tooltip title={t("components.MediaSelector.Filters.adjustFilters") || "Set color filters"}>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setFiltersModalOpen(true);
                    }}
                  >
                    <ColorLensOutlined />
                  </IconButton>
                </Tooltip>
              </ActionButtonContainer>
            )}
            {showScaling && (
              <ActionButtonContainer>
                <Tooltip title={t("kioForm.widgets.MediaSelectorField.scaleImage")}>
                  <IconButton
                    color="primary"
                    size="small"
                    ref={showScalingButtonRef}
                    onClick={() => {
                      setScalingPopoverOpen(true);
                    }}
                  >
                    <FitScreen />
                  </IconButton>
                </Tooltip>
              </ActionButtonContainer>
            )}
            <ActionButtonContainer>
              <Tooltip title={t("kioForm.widgets.MediaSelectorField.removeMedia") || "Remove"}>
                <IconButton color="primary" size="small" onClick={handleUnselectMedia}>
                  <RemoveCircleOutlineOutlined />
                </IconButton>
              </Tooltip>
            </ActionButtonContainer>
          </ActionButtonRow>

          {(!!markers || showFocalPointButton) && imageDimensions && (
            <MarkerContainer
              style={getMarkerAspectStyling(aspectRatio, imageDimensions)}
              onDrop={handleDrop}
              onDragOver={allowDrop}
            >
              {showFocalPointButton && (
                <FocalPointMarker style={{ top: `${currentFocalPoint.y}%`, left: `${currentFocalPoint.x}%` }} />
              )}
              {formData.scale != undefined && !isNaN(Number(formData.scale)) && formData.scale != 1 && (
                <ScaleIndicator>
                  {t("kioForm.widgets.MediaSelectorField.scaledByN", { n: formData.scale })}
                </ScaleIndicator>
              )}

              {!!markers &&
                markers!.map((marker) => (
                  <Marker
                    key={marker.index}
                    draggable
                    onDragStart={(e) => handleDragStart(marker.index, e)}
                    style={{ top: `${marker.y}%`, left: `${marker.x}%` }}
                  >
                    {marker.index + 1}
                  </Marker>
                ))}
            </MarkerContainer>
          )}

          <MediaPreview media={selectedMedia} setImageDimensions={setImageDimensions} filterProps={formData.filters} />
          {showFocalPointButton && (
            <FocalPointModal
              mediaItem={selectedMedia}
              originalFocalPoint={validNumber(formData.focalPoint?.x) ? formData!.focalPoint : DEFAULT_FOCAL_POINT}
              open={focalPointModalOpen}
              handleClose={() => setFocalPointModalOpen(false)}
              onFocalPointChange={onFocalPointChange}
              filters={formData.filters}
            />
          )}
          {showColorFilters && (
            <ColorFiltersModal
              mediaItem={selectedMedia}
              originalFilters={
                formData.filters && Object.keys(formData.filters).length ? formData.filters : DEFAULT_FILTERS
              }
              open={filtersModalOpen}
              handleClose={() => setFiltersModalOpen(false)}
              onFiltersChange={onFiltersChange}
            />
          )}
          {showScaling && (
            <ScalingPopover
              anchorRef={showScalingButtonRef}
              value={formData.scale ?? 1}
              open={scalingPopoverOpen}
              handleClose={() => setScalingPopoverOpen(false)}
              onChange={onScaleChange}
            />
          )}
        </PreviewContainer>
      );
    }
    if (!selectedMedia && !disabled && !readonly) {
      return (
        <ButtonBase onClick={toggleMediaDrawer} disabled={disabled || readonly}>
          <PreviewContainer style={aspectRatioStyle}>
            <SelectMediaLabel variant="button">
              <PermMediaOutlined />
              {selectLabel}
            </SelectMediaLabel>
          </PreviewContainer>
        </ButtonBase>
      );
    }
  };

  return (
    <Container>
      {title && <KioTitle title={title} description={schema.description} level={2} />}

      <input
        id={id}
        type="hidden"
        value={formData.mediaId || ""}
        disabled={disabled}
        required={required}
        readOnly={readonly}
      />

      {getContentElement()}
    </Container>
  );
};

export default MediaSelectorField;

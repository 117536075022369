import type { FC } from "react";
import { useEffect } from "react";
import i18next from "i18next";
import type ApplicationInstance from "../../declarations/models/ApplicationInstance";
import Api from "../../services/Api";
import type { SideMenuDef } from "@/components/SideMenu/SideMenu";
import SideMenu from "@/components/SideMenu/SideMenu";
import { MenuLoader } from "@/framework/MenuLoader";
import { useAsyncSafeState } from "@/hooks/useAsyncSafeState";
import { useLoadingState } from "@/hooks/useLoadingState";
import { groupBy } from "@/utils/arrays";
import { sortString } from "@/utils/sort";
import { getLanguageCode, resolvePotentiallyLocalizedString } from "@/utils/obj";

/**
 * Sorts menu items based on whether they exist in array pinned_owners in local storage.
 * */
const sortWithPriorityOfPinned = (a: string, b: string) => {
  const pinnedOwnersFromLocalStorage = localStorage.getItem("pinned_owners");
  if (pinnedOwnersFromLocalStorage) {
    try {
      const pinnedOwners = pinnedOwnersFromLocalStorage ? JSON.parse(pinnedOwnersFromLocalStorage) : [];
      if (Array.isArray(pinnedOwners)) {
        return sortString(
          pinnedOwners.includes(a.toString()) ? "000" + a : a,
          pinnedOwners.includes(b.toString()) ? "000" + b : b
        );
      }
    } catch (e) {
      console.error("Unable to parse pinned owners", e);
    }
  }
  return sortString(a, b);
};

export const MainMenu: FC = () => {
  const { isLoading, startLoading, stopLoading } = useLoadingState();
  const [menus, setMenus] = useAsyncSafeState<Array<SideMenuDef>>([]);
  const selectedLocale = getLanguageCode(i18next.language);
  const getLocalizedString = resolvePotentiallyLocalizedString(selectedLocale);

  useEffect(() => {
    startLoading();
    const getInstances = Api.getAllInstances();
    getInstances
      .fetchDirect(null)
      .then((page) => (page?.items || []) as Array<ApplicationInstance>)
      .then((instances) =>
        groupBy(instances, (instance) => getLocalizedString(instance.owner_name) || "?").map((group) => ({
          label: group.key,
          items: group.items.map((instance) => ({
            label: instance.name || "?",
            path: `../${instance.path}${instance.default_action_params}`,
          })),
        }))
      )
      .then((groupedMenuData) => groupedMenuData.sort((a, b) => sortWithPriorityOfPinned(a.label, b.label)))
      .then(setMenus)
      .finally(stopLoading);
    return getInstances.abort;
  }, [startLoading, stopLoading, setMenus]);

  if (isLoading) {
    return <MenuLoader numberOfMenus={2} numberOfItems={2} />;
  }

  return <SideMenu menus={menus} showDocumentSearch />;
};

export default MainMenu;

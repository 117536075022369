import type { FC } from "react";
import { useRef, useState } from "react";
import { Code } from "@mui/icons-material";
import { Menu, styled, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import Styles from "../../../../assets/styles/Styles";
import type { ViewTypePreviewProps } from "./ViewTypePreview";
import ViewTypePreview, { PreviewButton } from "./ViewTypePreview";
import JsonContentPreview from "./JsonContentPreview";
import { StyledMenuItem } from "@/framework/KioForm/common/KioSelect";

export enum ViewMode {
  PREVIEW = "preview",
  JSON_SCHEMA = "json_schema",
  UI_SCHEMA = "ui_schema",
  FORM_DATA = "form_data",
}

export interface EditorPreviewProps extends ViewTypePreviewProps {}

const Container = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  backgroundColor: Styles.Colors.PREVIEW_BACKGROUND_COLOR,
  borderLeft: `1px solid ${Styles.Colors.PREVIEW_BACKGROUND_COLOR}`,
});

const ViewModeToggleButton = styled(PreviewButton)(
  ({ theme }) => `
  position: absolute;
  margin: 0;
  right: ${theme.spacing(2)};
  bottom: ${theme.spacing(2)};
`
);

export const EditorPreview: FC<EditorPreviewProps> = ({
  formData,
  schemas,
  selectedLocale,
  availableLocales,
  documentId,
  documentStatus,
}) => {
  const { t } = useTranslation("common");
  const toggleViewModeButtonRef = useRef<HTMLButtonElement>(null);
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.PREVIEW);
  const [viewModeMenuAnchorEl, setViewModeMenuAnchorEl] = useState<HTMLElement | null>(null);
  const viewModeMenuOpen = Boolean(viewModeMenuAnchorEl);

  const handleSelectViewMode = (vm: ViewMode) => {
    setViewMode(vm);
    setViewModeMenuAnchorEl(null);
  };

  return (
    <Container>
      {viewMode === ViewMode.PREVIEW && (
        <ViewTypePreview
          formData={formData}
          selectedLocale={selectedLocale}
          schemas={schemas}
          availableLocales={availableLocales}
          documentId={documentId}
          documentStatus={documentStatus}
        />
      )}
      {viewMode === ViewMode.JSON_SCHEMA && (
        <JsonContentPreview data={schemas.jsonSchema} schemaId={schemas.schema_id} />
      )}
      {viewMode === ViewMode.UI_SCHEMA && <JsonContentPreview data={schemas.uiSchema} schemaId={schemas.schema_id} />}
      {viewMode === ViewMode.FORM_DATA && <JsonContentPreview data={formData} schemaId={schemas.schema_id} />}
      <Tooltip title={t("views.cms.preview.selectViewMode")}>
        <ViewModeToggleButton
          ref={toggleViewModeButtonRef}
          aria-controls="view-modes"
          aria-haspopup="true"
          onClick={(e) => setViewModeMenuAnchorEl(e.target as HTMLElement)}
        >
          <Code />
        </ViewModeToggleButton>
      </Tooltip>
      <Menu
        id="view-modes"
        open={viewModeMenuOpen}
        onClose={() => setViewModeMenuAnchorEl(null)}
        anchorEl={viewModeMenuAnchorEl}
        anchorOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        autoFocus={false}
      >
        {Object.values(ViewMode).map((vm) => (
          <StyledMenuItem key={vm} selected={vm === viewMode} onClick={() => handleSelectViewMode(vm)} dense>
            {t(`views.cms.preview.viewMode.${String(vm).toLowerCase()}`)}
          </StyledMenuItem>
        ))}
      </Menu>
    </Container>
  );
};

export default EditorPreview;

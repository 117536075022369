import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, DialogActions } from "@mui/material";
import { useModalManager } from "./store";
import { FullScreenDialog } from "@/components/MediaSelector/MediaTypeTabPanel/FullScreenDialog";
import { ModalWrapper } from "@/components/MediaSelector/MediaTypeTabPanel/ModalWrapper";
import DocumentListModalContent from "@/components/DocumentSelector/DocumentListModalContent";

export const DocumentListModalContentModal: FC = () => {
  const currentModalId = useModalManager((s) => s.documentListModalContent.currentModalId);
  const modal = useModalManager((s) => s.documentListModalContent.modals[currentModalId]);
  const isOpen = useModalManager((s) => s.documentListModalContent.isOpen);
  const { t } = useTranslation("common");

  const onCloseModal = () => {
    if (!modal?.props.isDirty || window.confirm(t("generic.unsavedChangesMessage"))) modal?.meta.onClose();
  };

  return (
    <FullScreenDialog maxWidth={"xl"} fullWidth open={isOpen} onClose={onCloseModal} scroll={"paper"}>
      <ModalWrapper>{modal?.props && <DocumentListModalContent {...modal.props} />}</ModalWrapper>
      <DialogActions>
        <Button type="button" color="primary" variant="contained" onClick={onCloseModal}>
          {t("generic.close")}
        </Button>
      </DialogActions>
    </FullScreenDialog>
  );
};
